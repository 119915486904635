<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/security.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/security.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO PRACY</h4>
		</template>

		<template #title>
			<h2>Prawo ubezpieczeń społecznych</h2>
		</template>

		<template #text>
			<p>Obszar prawa ubezpieczeń społecznych obejmuje obejmuje usługi w zakresie:
			</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">odwołań od decyzji organu rentowego, m.in. w sprawie ustalenia podstawy
					wymiaru składek
					na ubezpieczenia społeczne;
				</li>
				<li class="offer__list-item">reprezentacji Pracodawcy w sporach sądowych z zakresu ubezpieczeń
					społecznych we wszystkich instancjach;
				</li>
				<li class="offer__list-item">zasady uzyskiwania emerytur pomostowych;</li>
				<li class="offer__list-item">zasad tworzenia i obsługi PPK;</li>
				<li class="offer__list-item">sporządzania opinii prawnych oraz informacji prawnych z zakresu prawa
					ubezpieczeń społecznych;
				</li>
				<li class="offer__list-item">reprezentacji Pracodawcy w sporach z organem rentowym w zakresie ustalania
					przyczyn wypadków przy pracy i ustalania chorób zawodowych.
				</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'social security law',
}
</script>
